import React, { useState } from "react";
import "../assets/css/Policies.sass";

export default function Policies() {
  const [agreement, setAgreement] = useState(false);

  function checkAgreement() {
    setAgreement(true);
  }

  return (
    <main className={`policies ${agreement ? "hide": ""}`}>
      <div className="text">
        <p>
          Para melhorar a sua experiência de navegação, utilizamos de cookies, entre outras tecnologias. De acordo com nossa Política de Privacidade, ao continuar navegando, você aceita estas condições.
        </p>
      </div>
      <div className="action">
        <button className="btn" onClick={checkAgreement}>Concordar e continuar</button>
      </div>

    </main>
  );
}