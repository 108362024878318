import React from 'react';
import "../assets/css/Cta.sass";
import msg from "../assets/img/msg.png";
import whatsapp from "../assets/img/whatsapp.png";

export default function Cta() {
  return (
    <div className="cta">
      <a href="https://mozak.hypnobox.com.br/?controle=Atendimento&acao=index&produto=30&" target="_blank">
        <img src={msg} alt="Mensagem" />
      </a>
      <a href="https://wa.me/5521997442478" target="_blank">
        <img src={whatsapp} alt="Whatsapp" />
      </a>
    </div>
  );
}