import React from 'react';
import '../assets/css/Footer.sass';
import Image from '../assets/img/footer.png'

export default function Footer() {
  return (
    <div className="footer-section">

      <div className="text">
        <p>Realização:</p>
        <img src={Image} />
        <span>CRECI RJ-008658/O</span>
      </div>

    </div>
  );
}