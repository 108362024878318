import React, { useState } from "react";
import Form from "../components/Form";
import "../assets/css/Hero.sass"
import InputMask from 'react-input-mask';
import DocumentMeta from "react-document-meta";
import Cta from '../components/Cta';
import Modal from '../components/Modal';
import MenuIcon from '../assets/img/menu.png';
import Background from '../assets/img/background-hero.jpg';
import BackgroundMobile from '../assets/img/background-hero.jpg';
import Afranio from '../assets/img/afranio_logo.png';
import Bsd from "../assets/img/bsd.png";
import Mozak from "../assets/img/mozak.png";

import icoswipe from '../assets//img/arrow-fat-line-down.svg'

export default function Hero() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const meta = {
    title: 'Corporativo - Mozak',
    description: 'Seu negócio merece estar na melhor localização do Leblon',
    // canonical: 'http://example.com/path/to/page',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'mozak,leblon,empreendimento,corporativo,lançamento,comercial,luxo,imobiliária,imóveis,imobiliárias,imobiliárias no rio de janeiro,imóveis no rio de janeiro,imóveis no rio,imóvel'
      }
    }
  };

  function openMenu() {
    // add class active to menu
    document.querySelector('.menu').classList.add('active');
  }

  function checkData(e) {

    if (checkbox) {


      if (name != "" && email != "" && phone != "") {
        sendData(name, email, phone);
      } else {
        alert('Preencha todos os campos antes de enviar');
      }

    } else {
      alert('Preencha o checkbox antes de enviar');
    }

  }

  function getMediaFromURL() {
    var url = window.location.href;
    var allParams = url.split('?');

    if (allParams.length < 2) {
      return 'Direct'
    }

    var params = allParams[1].split('&');

    var source, utm_source, utm_medium, utm_campaign;

    if (params.find(element => element.includes('gclid')) != undefined) {
      // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
      return 'Google Ads';
    }

    // if(params.find(element => element.includes('posicionamento')) != undefined){
    //   source = params.find(element => element.includes('posicionamento')).split('=')[1];
    //   return source
    // }

    if (params.find(element => element.includes('utm_source')) != undefined) {
      source = params.find(element => element.includes('utm_source')).split('=')[1];
      return source;
    }


    return 'Landing page';
  }

  function sendData(name, email, phone) {
    var midia = getMediaFromURL();
    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: JSON.stringify({
        nome: name,
        email: email,
        tel_celular: phone,
        id_produto: 30,
        midia: midia
      })
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        setName("");
        setEmail("");
        setPhone("");
        setCheckbox(false);
      })
  }

  function openModal() {
    document.querySelector('.modal').style.display = 'flex';
  }

  return (
    <div className="Hero">
      <DocumentMeta {...meta} />

      {/* <h1>100% VENDIDO</h1> */}

      <img className="bsd" src={Bsd} />
      {/* <img className="mozak" src={Mozak} /> */}
      {/* <img className="menu-icon" src={MenuIcon} onClick={openMenu} /> */}


      <div className="background-image" >
        <div className="banner">
          <h1>100% Vendido</h1>
          <p>Obrigado por fazer parte deste sucesso</p>
        </div>
        <div className="overlayer"></div>
        <img className="background desktop" src={Background} alt="logo" />
        <img className="background mobile" src={BackgroundMobile} alt="logo" />
        <img className="ico-mobile" src={icoswipe} alt="swipe down" />
        <img className="afranio" src={Afranio} alt="logo" />
      </div>


      <Cta />

      <Modal />

    </div>
  );
}