import React, { useState } from "react";
import Form from "../components/Form";
import "../assets/css/Home.sass"
import bsd from "../assets/img/bsd.png";
import InputMask from 'react-input-mask';
import DocumentMeta from "react-document-meta";
import Cta from '../components/Cta';
import Policies from '../components/Policies';
import Modal from '../components/Modal';
import Menu from '../components/Menu';
import Hero from '../views/Hero';
import FormSection from '../views/Form';
import Datasheet from '../views/Datasheet';
import Perfect from '../views/Perfect';
import Video from '../views/Video';
import Location from '../views/Location';
import Arch from '../views/Arch';
import People from '../views/People';
import Photos from '../views/Photos';
import Design from '../views/Design';
import Sea from '../views/Sea';
import Book from '../views/Book';
import Footer from '../views/Footer';
import ProcurandoMais from "./ProcurandoMais";
import AMozak from "../components/AMozak";

export default function Home() {

  return (
    <div className='Home'>
      {/* <Menu /> */}
      <Hero />
      <ProcurandoMais />
      {/* <FormSection /> */}
      {/* <Datasheet /> */}
      {/* <Perfect /> */}
      {/* <Video /> */}
      {/* <Location /> */}
      {/* <Arch /> */}
      {/* <People /> */}
      {/* <Photos />
      <Design /> */}
      {/* <Sea /> */}
      {/* <Book /> */}
      <AMozak />
      <Footer />
      <Policies />
    </div>
  );
}