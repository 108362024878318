import React, { useLayoutEffect } from 'react';
import 'swiper/css'
import 'swiper/css/navigation';
import { useState } from 'react';

import maris from '../assets/img/Maris.png'

import ReactPlayer from 'react-player';

import '../assets/css/ProcurandoMais.sass'

import video from '../assets/img/Video.mp4'

function ProcurandoMais(props) {
    const wordsList = ['Clientes', 'Receita', 'Autoridade'];

    const [index, setIndex] = useState(0);

    useLayoutEffect(() => {
        const timeInterval = setInterval(() => {
            if (index === wordsList.length - 1) {
                setIndex(0);
            } else {
                setIndex(index + 1);
            }
        }, 3000);
        return () => clearInterval(timeInterval);
    }, [index]);

    return (
        <section id="procurando-mais">
            <div className='title'>
                {/* <h1>Procurando mais</h1> <div style={{ width: `${wordsList[index].length * 29}px` }}><h2>{wordsList[index]}</h2></div><h1>para o seu negócio?</h1> */}
                <h1>Conheça nosso novo lançamento comercial</h1>
            </div>
            {/* <p className='sub-title'>Conheça nosso novo comercial na Rua Conde de Bernadotte, 55, no Leblon:</p> */}
            <div className='container'>
                <div className="carrosel">
                    <ReactPlayer url={video} width={"auto"} height={"100%"} playing={true} muted={true} controls={true} />
                    {/* <Swiper navigation={true} modules={[Navigation]} className="swiper">
                    <SwiperSlide>
                        <img src={img6} alt="imagem 6" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img src={img5} alt="imagem 5" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img src={img3} alt="imagem 3" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img src={img4} alt="imagem 4" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img src={img1} alt="imagem 1" />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img src={img2} alt="imagem 2" />
                    </SwiperSlide>
                    </Swiper> */}
                </div>
                <div className="content">
                    <img src={maris} alt='Maris' />
                    <p>Rua Conde de Bernadotte, 55, Leblon</p>
                    <ul>
                        <a href='https://marismozak.com.br/'><li>Único comercial alto padrão do Leblon, com a curadoria artística inédita de Lenny Niemeyer</li></a>
                        <a href='https://marismozak.com.br/'><li>Ideal para clínicas, consultórios, escritórios e ateliês</li></a>
                        <a href='https://marismozak.com.br/'><li>Espaços comerciais de 17m² a 67m²</li></a>
                    </ul>
                    <a className='saibamais' href='https://marismozak.com.br/' target='_blank'>SAIBA MAIS</a>
                </div>
            </div>
        </section>
    );
}

export default ProcurandoMais;